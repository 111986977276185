<template>
  <div>
    <PlatformItem
      v-for="item in platformData"
      :key="item.title"
      :introduction="item.introduction"
      :keyPoints="item.keyPoints"
      :imageFileName="item.imageFileName"
      :flipped="item.flipped"
    />
  </div>
</template>

<script>
import ReportStreamData from "@/data/reportStream.json";
import PlatformItem from "@/components/CreditAnalytics/ReportStream/PlatformItem.vue";

export default {
  name: 'Platform',
  components: {
    PlatformItem,
  },
  data() {
    return {
      platformData: ReportStreamData?.platform,
    };
  },
};
</script>
